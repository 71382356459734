<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>购买弹框信息</el-breadcrumb-item>
            <el-breadcrumb-item>添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="省份" :error="$v.form.collectprovince.$error ? '请输入省份' : ''">
          <el-input v-model="form.collectprovince"></el-input>
        </el-form-item>
        <el-form-item label="市" :error="$v.form.collectcity.$error ? '请输入市' : ''">
          <el-input v-model="form.collectcity"></el-input>
        </el-form-item>
        <el-form-item label="区域">
          <el-input v-model="form.collectarea"></el-input>
        </el-form-item>

      <el-form-item label="昵称">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
              <el-form-item label="手机号" :error="$v.form.collectphone.$error ? '请输入手机号' : ''">
          <el-input v-model="form.collectphone"></el-input>
        </el-form-item>
             <el-form-item label="所属商品id" :error="$v.form.productid.$error ? '请输入所属商品id' : ''">
          <el-input v-model="form.productid"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getdatilRequest } from "@/api/zr_productbuytips";
import ImageUpload from "@/components/imageUpload";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  typeName: "Articletypecreate",
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        name: "",

        addtime: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload
  },
  created() {
    this.form.id = this.$route.query["id"];

    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
      });
    },

    createAd() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));

        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      collectphone: {
        required
      },   collectprovince: {
        required
      },   collectcity: {
        required
      } ,productid:{
         required
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
