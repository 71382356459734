import request from '@/utils/request'
 
// 分页列表
export function getListRequest(params) {
  return request({
    url: '/admin/zr_productbuytips/getList',
    method: 'get',
    params
  })
}


 // 修改 
export function editRequest(data) {
  return request({
    url: '/admin/zr_productbuytips/edit',
    method: 'post',
    data
  })
}

 
// 获取 详情
export function getdatilRequest(params) {
  return request({
    url: '/admin/zr_productbuytips/getdatil',
    method: 'get',
    params
  })
}

// 删除 
export function delRequest(params) {
  return request({
    url: '/admin/zr_productbuytips/del',
    method: 'get',
    params
  })
}

 


// 修改状态
export function setStatesRequest(params) {
    return request({
      url: '/admin/zr_productbuytips/setStates',
      method: 'get',
      params
    })
  }

 